<template>
  <Popup ref="popupRef" width="380px" :show-cancel="false" :show-confirm="false">
    <div class="popup">
      <div class="detail" v-if="detail">
        <div class="nav">
          <div>
            <img class="arrow-left" src="@/assets/images/arrow-left-white.png">
          </div>
          <div class="title">任务详情</div>
          <div class="flex items-center justify-end">
            <img src="@/assets/images/icon-detail-white.png">
            <img class="ml8" src="@/assets/images/icon-share.png">
          </div>
        </div>
        <div class="card base">
          <div class="flex between-center">
            <div class="flex-1">
              <div class="flex items-center mb4">
                <div class="ptitle">{{detail.taskTitle}}</div>
                <!-- <img class="img-tag" src="@/assets/images/tui.png">
                <img class="img-tag" src="@/assets/images/ding.png">
                <img class="img-tag" src="@/assets/images/bao.png"> -->
              </div>
              <div class="flex items-center">
                <div class="pname">{{detail.projectName}}</div>
                <div class="ptag" v-if="taskTypeName">{{taskTypeName}}</div>
              </div>
            </div>
            <img class="avatar" src="@/assets/images/avatar-default.png">
          </div>
          <div class="flex between-center">
            <div class="price">+{{detail.taskPrice}} <span class="unit">元</span></div>
          </div>
          <div class="count">
            <div>
              <div class="num">{{getDurationTime(detail.duration)}}</div>
              <div>做单时间</div>
            </div>
            <div>
              <div class="num">{{getDurationTime(detail.auditDuration)}}</div>
              <div>审核时间</div>
            </div>
            <div>
              <div class="num">0</div>
              <div>完成数量</div>
            </div>
            <div>
              <div class="num">{{detail.num}}</div>
              <div>剩余数量</div>
            </div>
          </div>
          <div class="device-row">
            <div class="flex items-center">
              <img src="@/assets/images/phone.png">
              <div>{{platformName}}</div>
            </div>
            <div class="flex items-center">
              <img src="@/assets/images/repeat.png">
              <div>{{repeatDesc}}</div>
            </div>
          </div>
        </div>
        <!-- 风险提示 -->
        <div class="card tips mt12">
          <div>风险提示：</div>
          <div>1、诱导私加、私下交易，发布同类平台任务，举报有奖;</div>
          <div>2、需投资的任务请谨慎，亏损自负；</div>
          <div>3、请先领取再做任务，否则无赏金;</div>
          <div>4、请勿乱提交，有封号风险。</div>
        </div>
        <!-- 做单说明 -->
        <div class="card description mt12" v-if="detail.description">
          <div class="title-row">
            <img src="@/assets/images/icon-warn-fill.png">
            <span>做单说明</span>
          </div>
          <div class="content">{{detail.description}}</div>
        </div>
        <!-- 任务步骤 -->
        <div class="card step mt12">
          <div class="title-row">
            <img src="@/assets/images/icon-step.png">
            <span>任务步骤</span>
          </div>
          <div class="mt8" v-for="(item, index) in detail.steps" :key="index">
            <div class="step-title">
              <div class="sort">{{index + 1}}</div>
              <div>{{item.stepTitle}}</div>
            </div>
            <LoadImage v-if="item.stepType == 0" class="step-img" :src="item.stepImg || item.stepContent"/>
            <el-input v-else size="mini" disabled :value="item.stepContent"></el-input>
          </div>
        </div>
        <!-- 上传验证 -->
        <div class="card step mt12">
          <div class="title-row">
            <img src="@/assets/images/icon-step.png">
            <span>上传验证</span>
          </div>
          <div class="mt8" v-for="(item, index) in detail.verifySteps" :key="index">
            <div class="step-title">
              <div class="sort">{{index + 1}}</div>
              <div>{{item.stepTitle}}</div>
            </div>
            <LoadImage v-if="item.stepType == 1" class="step-img" :src="item.stepImg || item.stepContent"/>
            <el-input v-else size="mini" disabled :value="item.stepContent"></el-input>
          </div>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="bottom">
        <div class="btn-next">换一个</div>
        <div class="btn-accept">立即领取</div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import LoadImage from '@/components/LoadImage'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
export default {
  components: {
    Popup,
    LoadImage
  },
  data() {
    return {
      detail: null,
      publishInfo: null
    }
  },
  computed: {
    taskTypeName() {
      if(!this.detail || !this.publishInfo) return ''
      const list = this.publishInfo.taskTypeRules || []
      const target = list.find(item => item.taskType == this.detail.taskType)
      if(target) return target.name
    },
    platformName() {
      if(!this.detail) return ''
      const map = {
        'android': '安卓',
        'ios': '苹果'
      }
      return map[this.detail.requirePlatform] || '不限接单设备'
    },
    repeatDesc() {
      if(!this.detail) return ''
      if(this.detail.repeat) return '不可重复接单'
      const repeatTimeDesc = this.detail.repeatTimes == 0 ? '不限次' : `每天${this.detail.repeatTimes}次`
      const repeatDayDesc = this.detail.repeatDays == 0 ? '不限天' : `每天${this.detail.repeatDays}次`
      return repeatTimeDesc + ' ' + repeatDayDesc
    }
  },
  methods: {
    open(info) {
      info.detail.steps.forEach(item => {
        if(item.stepType == 0 || item.stepType == 6) {
          ossKeyToUrl({ key: item.stepContent }).then(ossRes => {
            item.stepContent = ossRes.url
          }).catch(err => {})
        }
      })
      info.detail.verifySteps.forEach(item => {
        if(item.stepType == 1) {
          ossKeyToUrl({ key: item.stepContent }).then(ossRes => {
            item.stepContent = ossRes.url
          }).catch(err => {})
        }
      })
      this.detail = info.detail
      this.publishInfo = info.publishInfo
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    getDurationTime(hour) {
      let minute = parseInt(hour * 60);
      if (minute < 60) {
        return minute + '分钟'
      } else if (60 <= minute && minute <= 1440) {
        let result = parseInt(minute / 60);
        return result + '小时'
      } else if (minute >= 1440) {
        let result = parseInt(minute / 1440);
        return result + '天'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 30px !important;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 12px;
}

::-webkit-scrollbar {
	width: 3px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #F6F6F6;
	border-radius: 32px;
}
.popup {
  position: relative;
}
.detail {
  height: 750px;
  padding: 12px 12px 70px;
  overflow-y: auto;
  border-radius: 20px;
  background: linear-gradient(to bottom, #ff9304, #f6f6f6 20%, #f6f6f6);
}
.nav {
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 12px;
  &>div {
    flex: 1;
  }
  img {
    width: 24px;
    height: 24px;
  }
  .title {
    font-size: 18px;
    text-align: center;
  }
  .arrow-left {
    width: 18px;
    height: 18px;
  }
  
}
.card {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
}
.base {
  background: linear-gradient(to bottom, rgba(255,255,255,.8), #fff);
  .avatar {
    width: 44px;
    height: 44px;
    border-radius: 22px;
  }
  .ptitle {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: bold;
  }
  .img-tag {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
  .pname {
    font-size: 12px;
    color: #65666F;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ptag {
    font-size: 11px;
    color: #B0B3BF;
    padding: 0 4px;
    height: 16px;
    line-height: 16px;
    border-radius: 8px;
    border: 1px solid #B0B3BF;
    margin-left: 4px;
  }
  .price {
    color: var(--theme-color-red);
    font-size: 20px;
    font-weight: bold;
    margin-top: 4px;
    .unit {
      font-size: 13px;
    }
  }
  .count {
    display: flex;
    margin-top: 8px;
    &>div {
      flex: 1;
      font-size: 11px;
      color: #B0B3BF;
      text-align: center;
      .num {
        font-size: 14px;
        font-weight: bold;
        color: #2A2A2A;
        margin-bottom: 2px;
      }
    }
  }
  .device-row {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    margin-top: 8px;
    color: #B0B3BF;
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
.tips {
  white-space: pre-line;
  font-size: 11px;
  color: #B0B3BF;
  line-height: 16px;
}
.title-row {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 12px;
  border-bottom: 1px solid #f5f5f5;
  img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}
.description {
  padding: 12px 12px 0;
  .content {
    font-size: 12px;
    color: #65666F; 
    padding: 12px 0;
    line-height: 16px;
    white-space: pre-line;
  }
}
.step {
  .step-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 8px;
    .sort {
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-size: 10px;
      color: var(--theme-color);
      border: 1px solid var(--theme-color);
      border-radius: 8px;
      text-align: center;
      margin-right: 8px;
    }
  }
  .step-img {
    width: 150px;
    height: 150px;
  }
}
.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  padding: 8px 20px 0;
  background-color: #fff;
  color: #fff;
  .btn-accept {
    flex: 1;
    height: 38px;
    line-height: 38px;
    margin-left: 10px;
    text-align: center;
    border-radius: 4px;
    background: linear-gradient(to right, #ffa335, var(--theme-color));
  }
  .btn-next {
    width: 122px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 4px;
    background-color: var(--theme-color-blue);
  }
  
}
</style>